import { Typography } from "@mui/material"
import { format, parse, parseISO } from "date-fns"
import { enqueueSnackbar, VariantType } from "notistack"

export const getValueText = (value: number | string) => {
  if (typeof value === "string") {
    return value
  }
  return value > 999999 ? `${(value / 1000000).toFixed(1)}M` : value > 999 ? `${(value / 1000).toFixed(1)}K` : value
}

export const getFilterText = (filter: any) => {
  if (!filter) return ""
  const stringifiedFilters = Object.keys(filter).map(
    (key, idx) => `${key}=${filter[key]}${Object.keys(filter).length - 1 === idx ? "" : "&"}`
  )
  let endString = ""
  for (const filterString of stringifiedFilters) {
    endString = endString.concat(filterString)
  }
  return `&${endString}`
}

export const getDate = (date: string) => {
  try {
    return format(parse(date, "yyyy-LL-dd", new Date()), "dd/LL/yyyy")
  } catch (e) {
    try {
      return format(parseISO(date), "dd/LL/yyyy")
    } catch (e) {
      return date
    }
  }
}

export function encodeData(token: string, expirationMinutes: number) {
  const now = new Date()
  const expirationSeconds = expirationMinutes * 60000
  const expirationDate = new Date(now.getTime() + expirationSeconds)

  const data = {
    token,
    date: expirationDate.toISOString()
  }

  const jsonString = JSON.stringify(data)
  const encodedString = btoa(jsonString)

  return encodedString
}

export function decodeData(encodedString: any) {
  try {
    const jsonString = atob(encodedString)
    const data = JSON.parse(jsonString)

    const now = new Date()
    const expirationDate = new Date(data.date)

    if (now > expirationDate) {
      return { expired: true, token: null }
    }

    return { expired: false, token: data.token }
  } catch (error) {
    return { expired: true, token: null }
  }
}

export const addCommasToNumber = (numberString: string): string => {
  const parts: string[] = []
  const startIndex = numberString.length % 3 || 3
  parts.push(numberString.slice(0, startIndex))

  for (let i = startIndex; i < numberString.length; i += 3) {
    parts.push(numberString.slice(i, i + 3))
  }

  return parts.join(",")
}

export const getArtistCode = (artistCode?: string) => {
  return artistCode || localStorage.getItem("artistCode") || "funding"
}

export const getArtistId = () => {
  return localStorage.getItem("offersArtistId") || ""
}

export const toastMessage = (message: string, variant: VariantType) => {
  enqueueSnackbar(message, {
    variant,
    anchorOrigin: { vertical: "top", horizontal: "right" }
  })
}
