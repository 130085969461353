import { lazy } from "react"

// project-imports
import GuestGuard from "utils/route-guard/GuestGuard"
import OffersCommonLayout from "layout/offers/CommonLayout"
import Loadable from "components/Loadable"

// render - login
const LandingPage = Loadable(lazy(() => import("pages/offers/public/LandingPage")))
const Onboarding = Loadable(lazy(() => import("pages/offers/public/Onboarding")))
const SearchSpotifyArtist = Loadable(lazy(() => import("pages/offers/public/SearchSpotifyArtistPage")))
const ContactInformation = Loadable(lazy(() => import("pages/offers/public/ContactInformation")))
const IncomeSelection = Loadable(lazy(() => import("pages/offers/public/IncomeSelection")))
const WelcomeBack = Loadable(lazy(() => import("pages/offers/public/WelcomeBack")))
const ThankYou = Loadable(lazy(() => import("pages/offers/overview/ThankYou")))

// ==============================|| AUTH ROUTES ||============================== //

const OffersLoginRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <GuestGuard>
          <OffersCommonLayout backgroundColor={"#fff"} layout={"partial"} />
        </GuestGuard>
      ),
      children: [
        {
          path: "",
          element: <SearchSpotifyArtist resetPersonalizedLink={true} />
        }
      ]
    },
    {
      path: "/",
      element: (
        <GuestGuard>
          <OffersCommonLayout backgroundColor={"#fff"} layout={"landing-text"} />
        </GuestGuard>
      ),
      children: [
        {
          path: "thanks",
          element: <ThankYou isLanding={true} />
        }
      ]
    },
    {
      path: "/s/:artistCode",
      element: (
        <GuestGuard>
          <OffersCommonLayout backgroundColor={"#fff"} layout={"blank"} />
        </GuestGuard>
      ),
      children: [
        {
          path: "",
          element: <Onboarding confirmedOfferOnly={true} />
        }
      ]
    },
    {
      path: "/:artistCode",
      element: (
        <GuestGuard>
          <OffersCommonLayout backgroundColor={"#fff"} layout={"blank"} />
        </GuestGuard>
      ),
      children: [
        {
          path: "",
          element: <Onboarding confirmedOfferOnly={false} />
        }
      ]
    },
    {
      path: "/:artistCode",
      element: (
        <GuestGuard>
          <OffersCommonLayout backgroundColor={"#fff"} layout={"login"} />
        </GuestGuard>
      ),
      children: [
        {
          path: "login",
          element: <WelcomeBack confirmedOfferOnly={false}  />
        }
      ]
    },
    {
      path: "/:artistCode",
      element: (
        <GuestGuard>
          <OffersCommonLayout backgroundColor={"#fff"} layout={"partial"} />
        </GuestGuard>
      ),
      children: [
        {
          path: "search",
          element: <SearchSpotifyArtist />
        },
        {
          path: "income",
          element: <IncomeSelection />
        },
        {
          path: "contact",
          element: <ContactInformation isLanding={false} />
        }
        /*{
          path: "login",
          element: <AuthLogin logoText={"offers"}  />
        },
        {
          path: "register",
          element: <AuthRegister logoText={"offers"}  />
        },
        {
          path: "forgot-password",
          element: <AuthForgotPassword/>
        },
        {
          path: "check-mail",
          element: <AuthCheckMail/>
        },
        {
          path: "reset-password",
          element: <AuthResetPassword/>
        },
        {
          path: "code-verification",
          element: <AuthCodeVerification/>
        }*/
      ]
    }
  ]
}

export default OffersLoginRoutes
