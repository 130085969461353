import { useNavigate, useParams } from "react-router-dom"
// project-imports
import { KButton, KLogo, KSpan } from "kahuna-base-react-components"
import ArrowLeftIcon from "../../../assets/images/chevron/arrow-left.svg"
import TunefundLogo from "components/logo/TunefundLogo"
import { getArtistCode } from "utils"

interface Props {
  handleDrawerOpen?: () => void
  layout?: string
  backgroundColor: string
}

const Header = ({ handleDrawerOpen, layout = "public", backgroundColor }: Props) => {
  const navigate = useNavigate()
  const { artistCode } = useParams()

  return (
    <div className="h-full">
      <div
        className={"relative"}
        style={{
          backgroundColor,
          backdropFilter: "blur(8px)",
          boxShadow: "none",
          borderRadius: 10,
          height: "100%"
        }}
      >
        {layout !== "blank" && (
          <div
            style={{
              backgroundColor,
              width: "100%",
              maxWidth: "100%",
              borderRadius: 10,
              height: "100%",
              display: "flex",
              alignItems: "center"
            }}
          >
            <div className="w-full px-6 py-2 flex flex-row items-start max-[850px]:!px-4">
              <div
                className="max-[600px]:!hidden flex"
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  flexGrow: 1,
                  justifyContent: "space-between"
                }}
              >
                {layout === "login" && (
                  <div onClick={() => navigate(-2)} className={"cursor-pointer "} style={{ minWidth: "120px" }}>
                    <KButton
                      text={"Go Back"}
                      background={"#F0F0F0"}
                      onClick={() => navigate(`/${getArtistCode(artistCode)}/`)}
                      leftIcon={ArrowLeftIcon}
                    />
                  </div>
                )}
                {(layout === "landing" || layout === "landing-text") && <div style={{ minWidth: "120px" }} />}
                <div style={{ textAlign: "left", display: "inline-block" }}>
                  <div onClick={() => navigate(`/${getArtistCode(artistCode)}/`)} className={"cursor-pointer"}>
                    {layout === "landing-text" ? (
                      // <KLogo primaryTextVisible={true} secondaryText="for artists" hideIcon={true} />
                      <TunefundLogo width={96} textVisible={true} />
                    ) : (
                      // <KLogo logoColor="white" hoverEnabled={true} width={48} height={48} />
                      <TunefundLogo width={26} textVisible={false} />
                    )}
                  </div>
                </div>
                <div
                  onClick={() => navigate(`/${getArtistCode(artistCode)}/login`)}
                  className={"cursor-pointer "}
                  style={{ minWidth: "120px", textAlign: "right" }}
                >
                  {layout !== "login" && layout !== "landing" && layout !== "landing-text" && (
                    <KSpan text={"Login"} color={"#111"} fontWeight={500} fontSize={16} />
                  )}
                </div>
              </div>
              <div
                className="max-[600px]:!flex hidden"
                style={{
                  width: "100%",
                  alignItems: "center",
                  justifyContent: layout === "landing-text" ? "center" : "space-between"
                }}
              >
                {layout === "login" && (
                  <div onClick={() => navigate(-2)} className={"cursor-pointer "} style={{ minWidth: "120px" }}>
                    <KButton
                      text={"Go Back"}
                      background={"#F0F0F0"}
                      onClick={() => navigate(`/${getArtistCode(artistCode)}/`)}
                      leftIcon={ArrowLeftIcon}
                    />
                  </div>
                )}
                <div style={{ textAlign: "left", display: "inline-block" }}>
                  <div onClick={() => navigate(`/${getArtistCode(artistCode)}/`)} className={"cursor-pointer"}>
                    {layout === "landing-text" ? (
                      // <KLogo primaryTextVisible={true} secondaryText="for artists" hideIcon={true} />
                      <TunefundLogo width={96} textVisible={true} />
                    ) : (
                      // <KLogo logoColor="white" hoverEnabled={true} width={48} height={48} />
                      <TunefundLogo width={26} textVisible={false} />
                    )}{" "}
                  </div>
                </div>
                <div
                  onClick={() => navigate(`/${getArtistCode(artistCode)}/login`)}
                  className={"cursor-pointer"}
                  style={{
                    display: layout === "landing-text" ? "none" : "block",
                    minWidth: "120px",
                    textAlign: "right"
                  }}
                >
                  {layout !== "login" && layout !== "landing-text" && (
                    <KSpan text={"Login"} color={"#111"} fontWeight={500} fontSize={16} />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Header
