import { Menu, Transition } from "@headlessui/react"

import React, { Fragment, useEffect, useState } from "react"
import { useNavigate, useParams, Link, useLocation } from "react-router-dom"
import "../../../pages/offers/css/offers.css"

import VectorIcon from "../../../assets/images/navigation/vector.svg"
import MenuFillIcon from "../../../assets/images/profile/menu-fill.svg"
import AvatarIcon from "../../../assets/images/profile/avatar.svg"
import UserFillIcon from "../../../assets/images/profile/user-filled.svg"
import LogoutIcon from "../../../assets/images/profile/log-out-filled.svg"
import useAuth from "../../../hooks/useAuth"
import { KLogo, KSpan } from "kahuna-base-react-components"
import placeholderPerson from "../../../assets/images/person.png"
import ProfileListElement from "./profile/ProfileListElement"
import ArrowLeftIcon from "../../../assets/images/chevron/arrow-left.svg"
import MediaQuery from "react-responsive"
import TunefundLogo from "components/logo/TunefundLogo"
import { getArtistCode } from "utils"

interface Props {
  handleDrawerOpen?: () => void
  layout?: string
  backgroundColor: string
}

const Header = ({ handleDrawerOpen, layout = "public", backgroundColor }: Props) => {
  const { logout, user } = useAuth()

  const [smallScreen, setSmallScreen] = useState<boolean>(false)

  const location = useLocation()
  const path = location.pathname

  const navigate = useNavigate()
  const { artistCode } = useParams()

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 850px)")
    const handleMediaChange = (e: MediaQueryListEvent) => setSmallScreen(e.matches)
    setSmallScreen(mediaQuery.matches)
    mediaQuery.addEventListener("change", handleMediaChange)

    return () => mediaQuery.removeEventListener("change", handleMediaChange)
  }, [])

  const handleLogout = async () => {
    try {
      await logout()
      navigate(`/${getArtistCode(artistCode)}/`, {
        state: {
          from: ""
        }
      })
    } catch (err) {
      console.error(err)
    }
  }

  const renderPicture = (width: number) => {
    if (user && user.photo) {
      return <img className={`rounded-full`} src={user.photo} alt="profile_pic" style={{ height: width, width }} />
    }
    return <img src={AvatarIcon} alt="profile_pic" style={{ height: width, width }} />
  }

  return (
    <div className="h-full rounded-t-[10px]">
      <div
        className={"relative"}
        style={{
          backgroundColor,
          backdropFilter: "blur(8px)",
          boxShadow: "none",
          borderRadius: layout === "partial" || layout === "landing-text" ? 10 : 0,
          display: "flex",
          height: "100%"
        }}
      >
        {layout !== "blank" && layout !== "landing-text" && (
          <div style={{ backgroundColor, height: "100%", width: "100%", maxWidth: "100%", borderRadius: 10 }}>
            <div className="h-full px-6 py-2 flex flex-row items-center rounded-t-[10px]  max-[850px]:!px-4">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexGrow: 1,
                  alignItems: "center",
                  justifyContent: "flex-start"
                }}
              >
                <div style={{ textAlign: "left", display: "inline-block" }}>
                  {
                    //<KLogo logoColor="white" height={48} width={48} hoverEnabled={true} />
                  }
                  <TunefundLogo width={26} textVisible={false} />
                </div>
                {path.includes("account") && (
                  <div className="ml-8">
                    <ProfileListElement
                      text={smallScreen ? "" : "Back to Funnel"}
                      href={`/${getArtistCode(artistCode)}/artist/overview`}
                      iconPath={ArrowLeftIcon}
                    />
                  </div>
                )}
              </div>
              <div className="flex gap-3 flex-row">
                <div>
                  <div className={"flex items-center"} style={{ gap: 12 }}>
                    <div
                      className={"flex bg-[#FFF] hover:bg-[#F3F3F3] gap-1"}
                      style={{ borderRadius: 10, padding: "10px" }}
                    >
                      {user?.label ? (
                        <div className="flex gap-2">
                          <KSpan text={"Label: "} color={"#111"} fontWeight={700} />
                          <KSpan text={user.label} color={"#111"} fontWeight={500} />
                        </div>
                      ) : (
                        <div className="flex">
                          <img
                            src={localStorage.getItem("offersCover") || placeholderPerson}
                            style={{ width: 20, height: 20, borderRadius: 20, marginRight: 4 }}
                          />
                          <KSpan
                            text={localStorage.getItem("offersArtistName") || ""}
                            color={"#111"}
                            fontWeight={500}
                          />
                        </div>
                      )}
                    </div>
                    <div className="flex items-center justify-center">
                      <img src={VectorIcon} />
                    </div>
                    <Menu as="div" className="ml relative z-50 ">
                      <div>
                        <Menu.Button>
                          <div
                            className="flex items-center gap-2 pr-3 pl-3"
                            style={{
                              height: 36,
                              borderRadius: 10,
                              boxShadow:
                                "0px 0px 0px 1px rgba(17, 17, 17, 0.04), 0px 1px 2px 0px rgba(17, 17, 17, 0.06)"
                            }}
                          >
                            <img className="h-[20px] w-[20px]" src={MenuFillIcon} alt="profile_pic" />
                            {renderPicture(20)}
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <div>
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 p-2 w-[264px] rounded-md shadow-lg py-1 bg-[#ffffff] ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col divide-y divide-gray-200 ">
                            <Menu.Item>
                              <div className="flex flex-row gap-2.5 px-[14px] py-3">
                                <Link to={"/account/profile"}>
                                  <div className="aspect-square">{renderPicture(40)}</div>
                                </Link>
                                <span className="flex flex-col gap-2">
                                  <KSpan
                                    text={`${user?.first_name} ${user?.last_name}`}
                                    color="#0A0D14"
                                    fontWeight={500}
                                  />
                                  <KSpan
                                    text={
                                      user?.label ? user.label : `${localStorage.getItem("offersArtistName") || "-"}`
                                    }
                                    color="#525866"
                                    lineHeight="16px"
                                    fontSize={12}
                                  />
                                </span>
                              </div>
                            </Menu.Item>
                            <div className="px-1 py-1  ">
                              <Menu.Item>
                                {({ active }) => (
                                  <ProfileListElement
                                    href={`/${getArtistCode(artistCode)}/account/profile`}
                                    text="Profile"
                                    iconPath={UserFillIcon}
                                  />
                                )}
                              </Menu.Item>
                            </div>

                            <div className="px-1 py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <ProfileListElement
                                    href="/account/profile"
                                    text="Log Out"
                                    iconPath={LogoutIcon}
                                    onClick={() => handleLogout()}
                                  />
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                <div className="px-[10px] mt-2">
                                  <KSpan text="v.1.0 - Terms & Conditions"></KSpan>
                                </div>
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </div>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {layout === "landing-text" && (
          <div
            className="flex justify-center items-center"
            style={{ backgroundColor, width: "100%", maxWidth: "100%", borderRadius: 10 }}
          >
            <div className="px-6 py-2 flex flex-row items-start rounded-t-[10px] max-[850px]:!px-4">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexGrow: 1,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <div>
                  {
                    //<KLogo hideIcon primaryTextVisible secondaryText="for artists" />
                  }
                  <TunefundLogo width={96} textVisible={true} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Header

/**
 * <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    MenuListProps={{
                      "aria-labelledby": "basic-button"
                    }}
                    sx={{
                      // Remove backdrop filter
                      "& .MuiBackdrop-root": {
                        backgroundColor: "transparent !important"
                      }
                    }}
                  >
                    <MenuItem onClick={() => handleLogout()}>
                      <KButton
                        onClick={() => {
                          // Do Nothing
                        }}
                        text={"Profile"}
                        height={"20px"}
                        background={"transparent"}
                        shadowDisabled={true}
                        leftIcon={LogoutIcon}
                      />
                    </MenuItem>
                    <MenuItem onClick={() => handleLogout()}>
                      <KButton
                        onClick={() => {
                          // Do Nothing
                        }}
                        text={"Logout"}
                        height={"20px"}
                        background={"transparent"}
                        shadowDisabled={true}
                        leftIcon={LogoutIcon}
                      />
                    </MenuItem>
                  </Menu>
 */
