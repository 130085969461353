import { User } from "./users"
import { Artist } from "./artists"
import { PaginatedData } from "./root"
import { CompaniesProps, Company, CompanyBreakDown } from "./companies"
import { Album, Track } from "./tracks"

export type JobReport = {
  readCount: number
  updateCount: number
  insertCount: number
  deleteCount: number
  logs: string[]
  errorLogs: string[]
  warningLogs: string[]
}

export type RoyaltyReportRow = {
  distributor: string
  period: string
  platform: string
  transactionDate: string
  songTitle: string
  isrc: string
  created: string
  territory: string
  quantity: number
  currency: string
  segment: string
  segmentDescription: string
  netQuantity: number
  grossProgress: number
  netProgress: number
  splitRate: number
  channelId: string
  isVideo: boolean
}

export type RoyaltyReport = {
  id: number
  user: User
  userId: number
  sheetId: string
  spotifyId: string
  artist: Artist
  progress: number
  validIsrc: boolean
  created: string
  isrcList: string
  distributor: string
}

export interface ConfirmedOfferOption {
  id: number
  name: string
  value: string
  description: string
  multiplier: number
  type: string
  primary: boolean
}

export interface ConfirmedOffer {
  id: number
  spotifyId: string
  created: string
  advanceOfferUsd: number
  buyoutOfferUsd: number
  approved: boolean
  artist: Artist
  user: User
  userApproved: boolean
  lenContract: string
  incomePaid: string
  selectedOptionId: number
  selectedOption?: ConfirmedOfferOption
  confirmedTotal: number
  type: string
  spotifyArtistAccount: SpotifyArtistAccount
}

export interface SpotifyArtistAccount {
  id: number
  status: string
  spotifyId: string
  royaltyReportId: number
  timeline: string
  created: string
  reportUploaded: boolean
  userId: number
}

export interface SongSelection {
  id: number
  isrc: string
  created: string
  ownership: number
  spotifyArtistAccountId: number
  confirmedOfferId: number
  advanceOfferUsd: number
  buyoutOfferUsd: number
  selected: boolean
  track: Track
  albumId: number | string
  album: Album
}

export type ReportsContextType = {
  paginatedConfirmedOfferList: PaginatedData<ConfirmedOffer>
  fetchConfirmedOfferList: (orderBy: string, limit: number, offset: number) => Promise<void>
}

export interface ReportsProps {
  paginatedConfirmedOfferList: PaginatedData<ConfirmedOffer>
}

export interface ReportsActionProps {
  type: string
  payload?: ReportsProps
}

export const dynamicFormat = {
  period: 1,
  platform: 2,
  transaction_date: 3,
  song_title: 4,
  isrc: 5,
  territory: 6,
  quantity: 7,
  currency: 8,
  segment: 9,
  segment_description: 10,
  net_quantity: 11,
  gross_progress: 12,
  net_progress: 13,
  split_rate: 14
}

export enum DistributorTypes {
  ORCHARD = "orchard",
  INGROOVES = "ingrooves",
  ONERPM = "onerpm",
  SOUND_ON = "sound_on",
  DISTRO_KID = "distro_kid",
  TUNE_CORE = "tune_core",
  BELIEVE = "believe",
  OTHER = "other",
  DYNAMIC_MAPPING = "dynamic_mapping"
}

export enum SpotifyArtistAccountStatus {
  IN_PROGRESS = "in_progress",
  CREATED_CONFIRMED_OFFER = "created_confirmed_offer",
  FAILED_TO_CREATE_CONFIRMED_OFFER = "failed_to_create_confirmed_offer"
}

export enum SpotifyArtistAccountTimeline {
  SPOTIFY = "spotify",
  CHART_METRIC = "chart_metric"
}
